import React from "react";
import Carousel from "react-slick";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";
import carouselStyle from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.jsx";
import image1 from "assets/img/testimonial1.png";
import footerbg from "assets/img/footerbg.png";
import { Card } from "@material-ui/core";
import BannerLogo from "assets/img/banner-logo.png";
import { FaTwitter, FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import Button from "components/CustomButtons/Button.jsx";
import { Link } from "gatsby";

class FooterSection extends React.Component {
  render() {
    const { classes } = this.props;
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false
      };
    return (
      <div className={classes.section}>
        <div>
          <GridContainer>
            <GridItem xs={12} md={4} sm={6} style={{}}
            container
            direction="row"
            justify="center"
            alignItems="center"
            >
                <img src={BannerLogo} width="300" style={{}}/>
            </GridItem>
            <GridItem xs={12} sm={6} md={8}>
            <GridContainer  style={{margin: "0",paddingTop:"1em",backgroundSize:"contain",backgroundRepeat:"no-repeat",backgroundPosition:"center",backgroundImage:"url(" + footerbg + ")"}}>
                    <GridItem xs={12} sm={6} md={3} style={{}}>
                        <Link to="/services">
                          <h3>Services</h3>
                          <p>Trading & Sourcing </p>
                          <p>Manufacturing</p>
                          <p>Installations</p>
                        </Link>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={3} style={{}}>
                        <Link to="/about">
                          <h3>About Us</h3>
                          <p>Company Overview </p>
                        </Link>
                        <Link to="/partners">
                        <p>Our Partners & Networks</p>
                        </Link>
                        <Link to="/services#activities"><p>Our Activities </p></Link>
                        <p>E-Broucher </p>
                        <p>Careers  </p>
                        <Link to="/contact">
                          <p>Contact Us </p>
                        </Link>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} style={{}}>
                        <h3>Insights</h3>
                        <p>CEO Message </p>
                        <Link to="/products#brands">
                          <p>Our Brands</p>
                        </Link>
                        <p>Terms & Conditions</p>

                        <h3>Address</h3>
                        <p>ISTIQLAL STREET,<br/>INVEST BANK BUILDING/ CORNICHE VIEW TOWER, 102B,<br/>ABU DHABI , UNITED ARAB EMIRATES. PO BOX : 72269</p>

                        <h3>Contact</h3>
                        <p>Email: <a href="mailto:almidiaf@eim.ae">almidiaf@eim.ae</a></p>
                        <p>Tel: +971 (0) 2 444 2455</p>
                        <p>Fax: +971 2 444 2454</p>
                        <br />
                        <br />
                        <br />
                    </GridItem>
                </GridContainer>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
              <GridItem lg={12} style={{textAlign:"center"}}>
              <Button
                href="#"
                // target="_blank"
                color="transparent"
                // className={classes.navLink}
                >
                    <FaTwitter/>
                </Button>
                <Button
                href="#"
                // target="_blank"
                color="transparent"
                // className={classes.navLink}
                >
                    <FaFacebook/>
                </Button>
                <Button
                href="#"
                // target="_blank"
                color="transparent"
                // className={classes.navLink}
                >
                    <FaInstagram/>
                </Button>
                <Button
                href="#"
                // target="_blank"
                color="transparent"
                // className={classes.navLink}
                >
                    <FaLinkedin/>
                </Button>
              </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(carouselStyle, productStyle)(FooterSection);
