import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";
import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";

import { CssBaseline, Typography } from "@material-ui/core";

import NewsLetterSection from "../LandingPage/Sections/NewsLetterSection";
import FooterSection from "../LandingPage/Sections/FooterSection";
import partnersImg from "assets/img/partners.png";

import p1 from "assets/img/categories/ptc2.png";
import p2 from "assets/img/categories/htc2.png";
import p3 from "assets/img/categories/inc2.png";
import p4 from "assets/img/categories/ec2.png";
import p5 from "assets/img/categories/mec2.png";
import p6 from "assets/img/categories/wc2.png";
import p7 from "assets/img/categories/ssc2.png";
import p8 from "assets/img/categories/sfc2.png";
import p9 from "assets/img/categories/fc2.png";
import { Link } from "gatsby";

const dashboardRoutes = [];

class CategoriesSection extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="AL MIDIAFF INTERNATIONAL SUPPLIES"
          // brand="AL MIDIAFF INTERNATIONAL Co. LLC"
          // brand="AL MIDIAFF"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
        <CssBaseline />

        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={9} md={8} style={{}}>
              <h2 className={classes.title} style={{ color: "#6C648B" }}>Categories</h2>
            </GridItem>
          </GridContainer>
        </div>

        <div className={classes.container}>
          <GridContainer>
            {prodCategories.map((cat) =>

              <GridItem xs={12} sm={4} md={4} style={{padding: "15px"}} className={classes.categoryHover} >
                <Link to={cat.link}>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6} style={{textAlign:"center",display: "flex", flexDirection: "column", justifyContent: "center"}}
                  >
                    <p style={{fontSize: "1.125rem", lineHeight:" 1.5em"}}>{cat.name}</p>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6} style={{}}>
                    <img src={cat.img} style={{ width: "100%" }} />
                  </GridItem>
                </GridContainer>
                </Link>
              </GridItem>

            )}
          </GridContainer>
        </div>

      </div>



    );
  }
}

export default withStyles(landingPageStyle, productStyle)(CategoriesSection);

const prodCategories = [
  { name: 'Power Tools', link: '/product/power-tools', img: p1 },
  { name: 'Hand Tools', link: '/product/hand-tools', img: p2 },
  { name: 'Instrumentation', link: '/product/instrumentation', img: p3 },
  { name: 'Electrical', link: '/product/electrical', img: p4 },
  { name: 'Mechanical', link: '/product/mechanical', img: p5 },
  { name: 'Welding', link: '/product/welding', img: p6 },
  { name: 'Stainless Steel', link: '/product/stainless-steel', img: p7 },
  { name: 'PPE & Safety', link: '/product/ppe-safety', img: p8 },
  { name: 'Furniture', link: '/product/furniture', img: p9 }
]