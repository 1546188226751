import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";
import { Hidden, Input, TextField } from "@material-ui/core";
import Button from "components/CustomButtons/Button.jsx";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import nlicon from "assets/img/nlicon.png";

class NewsLetterSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer style={{ margin: "0", color: "white" }}>
          <GridItem xs={12} md={6}>
            <h2>Get The Insights That Matter.</h2>
            <p className={classes.description}>
              Keep up to date with technology and innovation, now and in the future.
              </p>

            <form method="POST" id="zcampaignOptinForm" action="https://maillist-manage.com/weboptin.zc" target="_zcSignup">
              <TextField label="Email Address" variant="outlined" style={{ color: "white", borderColor: 'white' }} changeitem="SIGNUP_FORM_FIELD" name="CONTACT_EMAIL" id="EMBED_FORM_EMAIL_LABEL" />

              <Button color="success" name="SIGNUP_SUBMIT_BUTTON" id="zcWebOptin" type="submit">Subscribe</Button>
              <input type="hidden" id="fieldBorder" value="" />
              <input type="hidden" id="submitType" name="submitType" value="optinCustomView" />
              <input type="hidden" id="emailReportId" name="emailReportId" value="" />
              <input type="hidden" id="formType" name="formType" value="QuickForm" />
              <input type="hidden" name="zx" id="cmpZuid" value="12b4c43de" />
              <input type="hidden" name="zcvers" value="3.0" />
              <input type="hidden" name="oldListIds" id="allCheckedListIds" value="" />
              <input type="hidden" id="mode" name="mode" value="OptinCreateView" />
              <input type="hidden" id="zcld" name="zcld" value="1a14b57fb411f0f0" />
              <input type="hidden" id="document_domain" value="" />
              <input type="hidden" id="zc_Url" value="publ.maillist-manage.com" />
              <input type="hidden" id="new_optin_response_in" value="0" />
              <input type="hidden" id="duplicate_optin_response_in" value="0" />
              <input type="hidden" name="zc_trackCode" id="zc_trackCode" value="ZCFORMVIEW" />
              <input type="hidden" id="zc_formIx" name="zc_formIx" value="9a58926fa3874855e5c809b84b62707b280a44214293713e" />
              <input type="hidden" id="viewFrom" value="URL_ACTION" />
              <input type="hidden" id="scriptless" name="scriptless" value="yes" />
            </form>

            {/* <div>
                  <span style={{padding:"10px"}}>
                    <CheckCircleIcon /> 
                    </span>
                <span>
                    Your email has been successfully registered, headlines will be sent to you soon.
                </span>
              </div> */}
          </GridItem>
          <Hidden smDown>
            <GridItem xs={12} md={6}>
              <img src={nlicon} style={{ maxWidth: "80%" }} />
            </GridItem>
          </Hidden>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(productStyle)(NewsLetterSection);
